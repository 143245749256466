import React from "react";
import { Link } from "react-router-dom";

export default function ListItemJoin({ data }) {
  return (
    <>
      <div className="w-full  divide-y rounded-lg border border-gray-200 dark:border-neutral-400 flex flex-col">
        {data?.map(({ id, title, categoryApp }, i) => (
          <Link
            to={"/detail/" + categoryApp + "/" + id}
            key={i}
            className="px-6 py-4 w-full hover:bg-gray-50 dark:hover:bg-neutral-800 cursor-pointer text-base font-semibold"
          >
            {title}
          </Link>
        ))}
      </div>
    </>
  );
}
