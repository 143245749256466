import { useEffect } from "react";

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // console.log(
        //   "klik element",
        //   ref.current,
        //   !ref.current || ref.current.contains(event.target)
        // );
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.

    // How to use on function component:
    // const ref = useRef(); // set ref
    // useOnClickOutside(ref, () => setShowModal(false)); // set action
    // <div ref={ref}></div> // use in return
    [ref, handler]
  );
}
