import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../../../components/button/Button";

export default function ListItemSearch({ data }) {
  const { t } = useTranslation();
  return (
    <>
      <div className=" w-full  divide-y rounded-lg border border-gray-200 flex flex-col ">
        {data.map(({ id, title, categoryApp, description }, i) => (
          <Link
            to={"/detail/" + categoryApp + "/" + id}
            key={i}
            className="px-6 py-4 w-full hover:bg-gray-50 dark:hover:bg-neutral-800 cursor-pointer"
          >
            <div className="flex sm:gap-2 flex-col sm:flex-row">
              <h2 className="cursor-pointer text-2xl font-bold">{title}</h2>
              <div className=" sm:border border-gray-300 rounded-md shadow-sm sm:h-10 flex items-center">
                <h2 className="text-xs font-semibold sm:p-2 ">
                  {" "}
                  {categoryApp}
                </h2>
              </div>
            </div>
            <p className="text-base line-clamp-2">{description}</p>
          </Link>
        ))}
        {data.length === 0 && (
          <>
            <div className="flex justify-center flex-col items-center m-2">
              <p className="text-center">{t("basic:noDataSearch")}.</p>
              <Link className="underline text-primary-200" to={"/"}>
                {t("basic:linkBackToHome")}
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
}
