import React from "react";
import { useTranslation } from "react-i18next";
import ToggleTheme from "../darkmode/ThemeToggle";
import DropDownLngs from "../language/DropDownLngs";

export default function SimpleFooter() {
  const { t } = useTranslation();
  return (
    <div
      className="flex justify-between   items-center mx-2 "
      style={{ minHeight: "9.9vh" }}
    >
      <div className="flex space-x-1 text-base dark:text-gray-400 transition-colors">
        <label>
          &copy; {new Date().getFullYear()} Psikku
          <small className="hidden sm:inline">,</small>{" "}
        </label>
        <a
          className="hover:underline hidden sm:inline"
          href="https://www.psikku.com/policies/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("basic:privacyPolicy")}
        </a>{" "}
        <label className="hidden sm:inline">-</label>
        <a
          className="hover:underline hidden sm:inline"
          href="https://www.psikku.com/policies/terms-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("basic:termsCodition")}
        </a>
      </div>
      <div className="flex space-x-2">
        <ToggleTheme />
        <DropDownLngs />
      </div>
    </div>
  );
}
