export const DUMMY_APP = [
  { id: 1, name: "Web Assessor" },
  { id: 2, name: "Web Client" },
  { id: 3, name: "Mobile Assessee" },
];

export const DUMMY_FAQ = [
  {
    id: 1,
    title:
      "Apakah konselor, psikolog, dan biro psikologi dapat menggunakan layanan Psikku sebagai penunjang pekerjaan atau profesinya?",
    categoryApp: "General",
    platform: [],
    description:
      "Ya, bagi Anda yang memiliki kualifikasi pada bidang konselor, psikologi, biro psikologi, hingga dosen, dapat menggunakan layanan Psikku yang sesuai dengan kebutuhan maupun pengajuan kerjasama.",
    htmlContent: null,
    idTopicJoin: [3, 5, 7, 9, 11, 13, 15, 17, 19],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 2,
      },
    ],
  },
  {
    id: 2,
    title:
      "Can counselors, psychologists, and psychology bureaus use Psikku services to support their work or profession?",
    categoryApp: "General",
    platform: [],
    description:
      "Yes, for those of you who have qualifications in the field of counselors, psychology, psychology bureaus, to lecturers, you can use Psikku services that suit your needs or apply for collaboration.",
    htmlContent: null,
    idTopicJoin: [4, 6, 8, 10, 12, 14, 16, 18, 20],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 1,
      },
    ],
  },
  {
    id: 3,
    title:
      "Apakah Psikku merupakan sejenis sistem HRIS atau sistem informasi kepegawaian?",
    categoryApp: "General",
    platform: [],
    description:
      "Psikku berfokus pada layanan untuk peningkatan kualitas sumber daya manusia melalui assessment, training, konsultasi, hingga monitoring KPI pegawai. Salah satu produk kami yaitu Platform Psikku Assessment (Pass) berfungsi untuk memudahkan perusahaan dan organisasi dalam menyaring kandidat yang tepat, membuat talent-pool, dan proses seleksi, promosi, maupun mutasi jabatan. Platform Psikku Pass depat kompatibel dengan kamus kompetensi yang bisa di kustomisasi oleh masing-masing HRD perusahaan maupun kepegawaian organisasi. Semua proses dapat dilakukan secara online.",
    htmlContent: null,
    idTopicJoin: [1, 5, 7, 9, 11, 13, 15, 17, 19],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 4,
      },
    ],
  },
  {
    id: 4,
    title: "Is Psikku a kind of HRIS system or a personnel information system?",
    categoryApp: "General",
    platform: [],
    description:
      "Psikku focuses on services to improve the quality of human resources through assessment, training, consultation, and monitoring of employee KPIs. One of our products, Psikku Assessment Platform (Pass) serves to facilitate companies and organizations in screening the right candidates, creating talent pools, and the selection, promotion, and job transfer processes. The Psikku Pass platform can be compatible with competency dictionaries that can be customized by each company's HRD and organizational staff. All processes can be done online.",
    htmlContent: null,
    idTopicJoin: [2, 6, 8, 10, 12, 14, 16, 18, 20],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 3,
      },
    ],
  },
  {
    id: 5,
    title: "Sektor apa saja yang Psikku layani?",
    categoryApp: "General",
    platform: [],
    description:
      "Psikku melayani semua sektor industri. Termasuk pendidikan (mulai dari SMA/SMK dan Perguruan Tinggi), pemerintahan maupun swasta.",
    htmlContent: null,
    idTopicJoin: [1, 3, 7, 9, 11, 13, 15, 17, 19],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 6,
      },
    ],
  },
  {
    id: 6,
    title: "What sectors does Psikku serve?",
    categoryApp: "General",
    platform: [],
    description:
      "Psikku caters to all industrial sectors. Including education (starting from high school / vocational school and university), government and private.",
    htmlContent: null,
    idTopicJoin: [2, 4, 8, 10, 12, 14, 16, 18, 20],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 5,
      },
    ],
  },
  {
    id: 7,
    title:
      "Apakah produk Pass Talent-Mapping dapat digunakan untuk tes secara bersamaan?",
    categoryApp: "General",
    platform: [],
    description:
      "Ya, Pass Talent-Mapping dapat digunakan secara bersamaan maupun terpisah (sendiri-sendiri) oleh user. Semua proses tes dilajukan secara online.",
    htmlContent: null,
    idTopicJoin: [1, 3, 5, 9, 11, 13, 15, 17, 19],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 8,
      },
    ],
  },
  {
    id: 8,
    title:
      "Can the Talent-Mapping Pass product be used for testing simultaneously?",
    categoryApp: "General",
    platform: [],
    description:
      "Yes, the Talent-Mapping Pass can be used simultaneously or separately by the user. All test processes are submitted online.",
    htmlContent: null,
    idTopicJoin: [2, 4, 6, 10, 12, 14, 16, 18, 20],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 7,
      },
    ],
  },
  {
    id: 9,
    title: "Apa saja ekosistem produk dan layanan Psikku?",
    categoryApp: "General",
    platform: [1, 3, 5, 7, 11, 13, 15, 17, 19],
    description:
      "Pass (Platform asesmen dan psikometri berbasis automasi maupun asesor), Pduli (Platform konsultasi online untuk kesehatan mental dan karir), Plajari (Platform agregator training dan sertifikasi), dan Performs (Platform absensi dan penilaian kinerja secara online).",
    htmlContent: null,
    idTopicJoin: [1, 3, 5, 7, 11, 13, 15, 17, 19],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 10,
      },
    ],
  },
  {
    id: 10,
    title: "What is the Psikku ecosystem of products and services?",
    categoryApp: "General",
    platform: [],
    description:
      "Pass (automation and assessor based psychometric and assessment platform), Pduli (online consultation platform for mental health and career), Plajari (training and certification aggregator platform), and Performs (online attendance and performance appraisal platform).",
    htmlContent: null,
    idTopicJoin: [2, 4, 6, 8, 12, 14, 16, 18, 20],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 9,
      },
    ],
  },
  {
    id: 11,
    title:
      "Apakah produk Pass Talent-Mapping dapat dilakukan free trial sebelum klien membeli?",
    categoryApp: "General",
    platform: [],
    description: "Ya, silakan menghubungi customer support kami.",
    htmlContent: null,
    idTopicJoin: [1, 3, 5, 7, 9, 13, 15, 17, 19],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 12,
      },
    ],
  },
  {
    id: 12,
    title:
      "Can the Talent-Mapping Pass product be free trial before the client buys?",
    categoryApp: "General",
    platform: [],
    description: "Yes, please contact our customer support.",
    htmlContent: null,
    idTopicJoin: [2, 4, 6, 8, 10, 14, 16, 18, 20],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 11,
      },
    ],
  },

  {
    id: 13,
    title:
      "Apakah Pass Asessment bisa disesuaikan dengan kamus kompetensi perusahaan saya?",
    categoryApp: "General",
    platform: [],
    description:
      "Ya, Pass Assessment memiliki fitur psikometri yang dapat disesuaikan dengan kamus kompetensi persusahaan atau organisasi Anda.",
    htmlContent: null,
    idTopicJoin: [1, 3, 5, 7, 9, 11, 15, 17, 19],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 14,
      },
    ],
  },
  {
    id: 14,
    title:
      "Can the Pass Assessment be adapted to my company's competency dictionary?",
    categoryApp: "General",
    platform: [],
    description:
      "Yes, Pass Assessment has psychometric features that can be adapted to your company's or organization's competency dictionary.",
    htmlContent: null,
    idTopicJoin: [2, 4, 6, 8, 10, 12, 16, 18, 20],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 13,
      },
    ],
  },

  {
    id: 15,
    title: "Bagaimana cara pemesanan produk dan layanan?",
    categoryApp: "General",
    platform: [],
    description:
      "Silakan memilih produk yang dibutuhkan atau hubungi customer support Psikku untuk bantuan lebih lanjut.",
    htmlContent: null,
    idTopicJoin: [1, 3, 5, 7, 9, 11, 13, 17, 19],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 16,
      },
    ],
  },
  {
    id: 16,
    title: "How do I order products and services?",
    categoryApp: "General",
    platform: [],
    description:
      "Please select the required product or contact Psikku customer support for further assistance.",
    htmlContent: null,
    idTopicJoin: [2, 4, 6, 8, 10, 12, 14, 18, 20],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 15,
      },
    ],
  },
  {
    id: 17,
    title: "Bagaimana cara pembayaran produk dan layanan?",
    categoryApp: "General",
    platform: [],
    description:
      "Pembayaran dapat dilakukan melalui online payment gateway maupun dan skema pembayaran yang sesuai kesepakatan.",
    htmlContent: null,
    idTopicJoin: [1, 3, 5, 7, 9, 11, 13, 15, 19],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 18,
      },
    ],
  },
  {
    id: 18,
    title: "How do I pay for products and services?",
    categoryApp: "General",
    platform: [],
    description:
      "Payments can be made through an online payment gateway or a payment scheme according to the agreement.",
    htmlContent: null,
    idTopicJoin: [2, 4, 6, 8, 10, 12, 14, 16, 20],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 17,
      },
    ],
  },
  {
    id: 19,
    title:
      "Saya memiliki pertanyaan lain terkait produk Psikku, dimana saya bisa menghubungi?",
    categoryApp: "General",
    platform: [],
    description:
      "Anda dapat menghubungi customer service kami ataupun ke alamat kantor kami yang tertera pada halaman kontak.",
    htmlContent: null,
    idTopicJoin: [1, 3, 5, 7, 9, 11, 13, 15, 17],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 20,
      },
    ],
  },
  {
    id: 20,
    title:
      "I have other questions regarding Psikku products, where can I contact?",
    categoryApp: "General",
    platform: [],
    description:
      "You can contact our customer service or to our office address listed on the contact page.",
    htmlContent: null,
    idTopicJoin: [2, 4, 6, 8, 10, 12, 14, 16, 18],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 19,
      },
    ],
  },
];

export const DUMMY_FAQ_OLD = [
  {
    id: 1,
    title: "Bagaimana Cara Untuk Login untuk Assessee?",
    categoryApp: "Mobile Assessee",
    platform: ["IOS", "ANDROID"],
    description:
      "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting. Lorem Ipsum telah menjadi standar contoh teks sejak tahun 1500an, saat seorang tukang cetak yang tidak dikenal mengambil sebuah kumpulan teks dan mengacaknya untuk menjadi sebuah buku contoh huruf. Ia tidak hanya bertahan selama 5 abad, tapi juga telah beralih ke penataan huruf elektronik, tanpa ada perubahan apapun. Ia mulai dipopulerkan pada tahun 1960 dengan diluncurkannya lembaran-lembaran Letraset yang menggunakan kalimat-kalimat dari Lorem Ipsum, dan seiring munculnya perangkat lunak Desktop Publishing seperti Aldus PageMaker juga memiliki versi Lorem Ipsum. Sudah merupakan fakta bahwa seorang pembaca akan terpengaruh oleh isi tulisan dari sebuah halaman saat ia melihat tata letaknya. Maksud penggunaan Lorem Ipsum adalah karena ia kurang lebih memiliki penyebaran huruf yang normal, ketimbang menggunakan kalimat seperti 'Bagian isi disini, bagian isi disini', sehingga ia seolah menjadi naskah Inggris yang bisa dibaca. Banyak paket Desktop Publishing dan editor situs web yang kini menggunakan Lorem Ipsum sebagai contoh teks. Karenanya pencarian terhadap kalimat 'Lorem Ipsum' akan berujung pada banyak situs web yang masih dalam tahap pengembangan. Berbagai versi juga telah berubah dari tahun ke tahun, kadang karena tidak sengaja, kadang karena disengaja (misalnya karena dimasukkan unsur humor atau semacamnya)",
    htmlContent:
      "<p>Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan<br/>Dari html code</p>",
    idTopicJoin: [3, 5],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 2,
      },
    ],
  },
  {
    id: 2,
    title: "How to login Assessee?",
    categoryApp: "Mobile Assessee",
    platform: ["IOS", "ANDROID"],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
    htmlContent: "",
    idTopicJoin: [4, 6],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 1,
      },
    ],
  },
  {
    id: 3,
    title: "Bagaimana Cara Untuk Login untuk Client?",
    categoryApp: "Web Client",
    platform: ["WEBSITE"],
    description:
      "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting. Lorem Ipsum telah menjadi standar contoh teks sejak tahun 1500an, saat seorang tukang cetak yang tidak dikenal mengambil sebuah kumpulan teks dan mengacaknya untuk menjadi sebuah buku contoh huruf. Ia tidak hanya bertahan selama 5 abad, tapi juga telah beralih ke penataan huruf elektronik, tanpa ada perubahan apapun. Ia mulai dipopulerkan pada tahun 1960 dengan diluncurkannya lembaran-lembaran Letraset yang menggunakan kalimat-kalimat dari Lorem Ipsum, dan seiring munculnya perangkat lunak Desktop Publishing seperti Aldus PageMaker juga memiliki versi Lorem Ipsum. Sudah merupakan fakta bahwa seorang pembaca akan terpengaruh oleh isi tulisan dari sebuah halaman saat ia melihat tata letaknya. Maksud penggunaan Lorem Ipsum adalah karena ia kurang lebih memiliki penyebaran huruf yang normal, ketimbang menggunakan kalimat seperti 'Bagian isi disini, bagian isi disini', sehingga ia seolah menjadi naskah Inggris yang bisa dibaca. Banyak paket Desktop Publishing dan editor situs web yang kini menggunakan Lorem Ipsum sebagai contoh teks. Karenanya pencarian terhadap kalimat 'Lorem Ipsum' akan berujung pada banyak situs web yang masih dalam tahap pengembangan. Berbagai versi juga telah berubah dari tahun ke tahun, kadang karena tidak sengaja, kadang karena disengaja (misalnya karena dimasukkan unsur humor atau semacamnya)",
    htmlContent: "",
    idTopicJoin: [1, 5],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 4,
      },
    ],
  },
  {
    id: 4,
    title: "How to login Client?",
    categoryApp: "Web Client",
    platform: ["WEBSITE"],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
    htmlContent: "",
    idTopicJoin: [2, 6],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 3,
      },
    ],
  },
  {
    id: 5,
    title: "Bagaimana Cara Untuk Login untuk Assessor?",
    categoryApp: "Web Assessor",
    platform: ["WEBSITE"],
    description:
      "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting. Lorem Ipsum telah menjadi standar contoh teks sejak tahun 1500an, saat seorang tukang cetak yang tidak dikenal mengambil sebuah kumpulan teks dan mengacaknya untuk menjadi sebuah buku contoh huruf. Ia tidak hanya bertahan selama 5 abad, tapi juga telah beralih ke penataan huruf elektronik, tanpa ada perubahan apapun. Ia mulai dipopulerkan pada tahun 1960 dengan diluncurkannya lembaran-lembaran Letraset yang menggunakan kalimat-kalimat dari Lorem Ipsum, dan seiring munculnya perangkat lunak Desktop Publishing seperti Aldus PageMaker juga memiliki versi Lorem Ipsum. Sudah merupakan fakta bahwa seorang pembaca akan terpengaruh oleh isi tulisan dari sebuah halaman saat ia melihat tata letaknya. Maksud penggunaan Lorem Ipsum adalah karena ia kurang lebih memiliki penyebaran huruf yang normal, ketimbang menggunakan kalimat seperti 'Bagian isi disini, bagian isi disini', sehingga ia seolah menjadi naskah Inggris yang bisa dibaca. Banyak paket Desktop Publishing dan editor situs web yang kini menggunakan Lorem Ipsum sebagai contoh teks. Karenanya pencarian terhadap kalimat 'Lorem Ipsum' akan berujung pada banyak situs web yang masih dalam tahap pengembangan. Berbagai versi juga telah berubah dari tahun ke tahun, kadang karena tidak sengaja, kadang karena disengaja (misalnya karena dimasukkan unsur humor atau semacamnya)",
    htmlContent: "",
    idTopicJoin: [1, 3],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 6,
      },
    ],
  },
  {
    id: 6,
    title: "How to login Assessor?",
    categoryApp: "Web Assessor",
    platform: ["WEBSITE"],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
    htmlContent: "",
    idTopicJoin: [2, 4],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 5,
      },
    ],
  },

  {
    id: 7,
    title: "Bagaimana cara membuat Projek Assessment",
    categoryApp: "Web Client",
    platform: ["WEBSITE"],
    description:
      "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting. Lorem Ipsum telah menjadi standar contoh teks sejak tahun 1500an, saat seorang tukang cetak yang tidak dikenal mengambil sebuah kumpulan teks dan mengacaknya untuk menjadi sebuah buku contoh huruf. Ia tidak hanya bertahan selama 5 abad, tapi juga telah beralih ke penataan huruf elektronik, tanpa ada perubahan apapun. Ia mulai dipopulerkan pada tahun 1960 dengan diluncurkannya lembaran-lembaran Letraset yang menggunakan kalimat-kalimat dari Lorem Ipsum, dan seiring munculnya perangkat lunak Desktop Publishing seperti Aldus PageMaker juga memiliki versi Lorem Ipsum. Sudah merupakan fakta bahwa seorang pembaca akan terpengaruh oleh isi tulisan dari sebuah halaman saat ia melihat tata letaknya. Maksud penggunaan Lorem Ipsum adalah karena ia kurang lebih memiliki penyebaran huruf yang normal, ketimbang menggunakan kalimat seperti 'Bagian isi disini, bagian isi disini', sehingga ia seolah menjadi naskah Inggris yang bisa dibaca. Banyak paket Desktop Publishing dan editor situs web yang kini menggunakan Lorem Ipsum sebagai contoh teks. Karenanya pencarian terhadap kalimat 'Lorem Ipsum' akan berujung pada banyak situs web yang masih dalam tahap pengembangan. Berbagai versi juga telah berubah dari tahun ke tahun, kadang karena tidak sengaja, kadang karena disengaja (misalnya karena dimasukkan unsur humor atau semacamnya)",
    htmlContent: "",
    idTopicJoin: [1, 3],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 8,
      },
    ],
  },
  {
    id: 8,
    title: "How to create Project Assessment?",
    categoryApp: "Web Client",
    platform: ["WEBSITE"],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
    htmlContent: "",
    idTopicJoin: [2, 4],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 7,
      },
    ],
  },
  {
    id: 9,
    title: "Bagaimana cara assessor menambahkan anggota team untuk project?",
    categoryApp: "Web Assessor",
    platform: ["WEBSITE"],
    description:
      "Lorem Ipsum adalah contoh teks atau dummy dalam industri percetakan dan penataan huruf atau typesetting. Lorem Ipsum telah menjadi standar contoh teks sejak tahun 1500an, saat seorang tukang cetak yang tidak dikenal mengambil sebuah kumpulan teks dan mengacaknya untuk menjadi sebuah buku contoh huruf. Ia tidak hanya bertahan selama 5 abad, tapi juga telah beralih ke penataan huruf elektronik, tanpa ada perubahan apapun. Ia mulai dipopulerkan pada tahun 1960 dengan diluncurkannya lembaran-lembaran Letraset yang menggunakan kalimat-kalimat dari Lorem Ipsum, dan seiring munculnya perangkat lunak Desktop Publishing seperti Aldus PageMaker juga memiliki versi Lorem Ipsum. Sudah merupakan fakta bahwa seorang pembaca akan terpengaruh oleh isi tulisan dari sebuah halaman saat ia melihat tata letaknya. Maksud penggunaan Lorem Ipsum adalah karena ia kurang lebih memiliki penyebaran huruf yang normal, ketimbang menggunakan kalimat seperti 'Bagian isi disini, bagian isi disini', sehingga ia seolah menjadi naskah Inggris yang bisa dibaca. Banyak paket Desktop Publishing dan editor situs web yang kini menggunakan Lorem Ipsum sebagai contoh teks. Karenanya pencarian terhadap kalimat 'Lorem Ipsum' akan berujung pada banyak situs web yang masih dalam tahap pengembangan. Berbagai versi juga telah berubah dari tahun ke tahun, kadang karena tidak sengaja, kadang karena disengaja (misalnya karena dimasukkan unsur humor atau semacamnya)",
    htmlContent: "",
    idTopicJoin: [1, 3],
    languageDefault: "ID",
    language: [
      {
        code: "EN",
        topikId: 10,
      },
    ],
  },
  {
    id: 10,
    title: "How to invite another Assessor in project?",
    categoryApp: "Web Assessor",
    platform: ["WEBSITE"],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
    htmlContent: "",
    idTopicJoin: [2, 4],
    languageDefault: "EN",
    language: [
      {
        code: "ID",
        topikId: 9,
      },
    ],
  },
];
