import React from "react";
import LogoFullImg from "../logo/LogoFullImg";
import SearchingInputMain from "../searching/SearchingInputMain";

export default function SimpleHeader({ children }) {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      {/* <div className="bg-secondary-200 text-gray-200 flex flex-col justify-center items-center w-full gap-4 py-20 border-b px-2"> */}
      <div
        className="bg-gray-50 text-gray-700 flex flex-col justify-center items-center w-full gap-4 py-20 border-b px-2
      dark:bg-neutral-900
      dark:text-gray-200
      dark:border-gray-400 transition-colors
      
      "
      >
        <div
          className="cursor-pointer"
          onClick={() => openInNewTab("https://www.psikku.com")}
        >
          <LogoFullImg className={"w-56 dark:white"} />
        </div>
        {children}
        <SearchingInputMain />
      </div>
    </>
  );
}
