import React from "react";
import { Link } from "react-router-dom";

const dummy = [
  { title: "An item" },
  { title: "A second item" },
  { title: "A third item" },
  { title: "A fourth item" },
  { title: "And a fifth one" },
];

export default function ListItemTitle({ data }) {
  return (
    <>
      <div className="w-full text-gray-800 space-y-2 shadow-sm dark:text-gray-200 flex flex-col">
        {data.map(({ id, title, categoryApp }, index) => (
          // <Card title={title} index={index} />

          <Link
            to={"/detail/" + categoryApp + "/" + id}
            key={index}
            className=" px-6 py-4 w-full bg-white dark:bg-neutral-900 hover:bg-gray-50 dark:hover:bg-neutral-900 cursor-pointer text-2xl font-bold border border-l-8 border-gray-200 border-b-6 hover:border-l-8 hover:border-l-primary-200 hover:border-t-transparent hover:border-b-transparent hover:border-r-0
            hover:bg-gradient-to-r from-primary-200 transition-all"
          >
            {title}
          </Link>
        ))}
      </div>
    </>
  );
}
