import React from "react";
import SimpleFooter from "../components/footer/SimpleFooter";

export default function BasicLayout({ children }) {
  return (
    <div className="bg-white text-gray-900 dark:bg-neutral-900 dark:text-gray-200 transition-colors">
      <div style={{ minHeight: "90vh" }}>{children}</div>
      <div className="container mx-auto sm:items-center h-full border-t border-gray-300 dark:border-gray-400 ">
        <SimpleFooter />
      </div>
    </div>
  );
}
