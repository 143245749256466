import React from "react";
import Logo from "../../assets/img/logo-full.webp";
import LogoWhite from "../../assets/img/psikku-w.webp";

export default function LogoFullImg({ className, white = false }) {
  const imgShow = () => {
    if (white) {
      return LogoWhite;
    }
    return Logo;
  };
  return (
    <>
      <img src={imgShow()} className={className} />
    </>
  );
}
