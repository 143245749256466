import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicContainer from "../../../../components/container/BasicContainer";
import SimpleHeader from "../../../../components/header/SimpleHeader";
import { DUMMY_FAQ } from "../../../../dummy/FAQData";
import ListApp from "../molekuls/ListApp";
import ListItemSearch from "../molekuls/ListItemSearch";
import { useParams } from "react-router-dom";
export default function TopikByApp() {
  const { t, i18n } = useTranslation();
  let { aplikasiName } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(
      DUMMY_FAQ.filter(
        (x) =>
          x.languageDefault === i18n.language.toUpperCase() &&
          x.categoryApp.toLowerCase().includes(aplikasiName.toLowerCase())
      )
    );
  }, [i18n.language, aplikasiName]);
  return (
    <>
      <SimpleHeader>
        <div>
          <h1 className="text-3xl font-bold  text-center">
            {t("basic:filterPage.h1_search")}
          </h1>
          <p className="text-base "> {t("basic:filterPage.search_title")}</p>
        </div>
      </SimpleHeader>
      <BasicContainer>
        <ListItemSearch data={data} />
        <div className="my-10">
          {/* <h3 className="text-lg font-semibold capitalize mb-4">
            {t("basic:topicAppBase")}
          </h3>
          <ListApp /> */}
        </div>
      </BasicContainer>
    </>
  );
}
