import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../functioGeneral/propsComponent";

export default function SimpleBreadcrumbs({
  list = [
    { name: "Home", link: "#" },
    { name: "Help", link: "#" },
  ],
}) {
  return (
    <>
      <nav className="rounded-md w-full overflow-auto">
        <ol className="list-reset flex text-base  text-primary-200 dark:text-primary-400 ">
          {list.map((n, key) => (
            <Fragment key={"frag-" + key}>
              {key != 0 && (
                <li key={"lis-" + key}>
                  <span className="mx-2">/</span>
                </li>
              )}
              <li key={"li-" + key}>
                <Link
                  key={"link-" + key}
                  to={n.link}
                  className={classNames(
                    key == list.length - 1 && "font-semibold cursor-default",
                    key != list.length - 1 && "underline",
                    "whitespace-nowrap"
                  )}
                >
                  {n.name}
                </Link>
              </li>
            </Fragment>
          ))}
        </ol>
      </nav>
    </>
  );
}
