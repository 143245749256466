import React from "react";
import { Routes, Route } from "react-router-dom";
import BasicLayout from "./layout/BasicLayout";
import HomePage from "./moduls/main/views/parts/HomePage";
import SearchingPage from "./moduls/main/views/parts/SearchingPage";
import DetailTopikPage from "./moduls/main/views/parts/DetailTopikPage";
import TopikByApp from "./moduls/main/views/parts/TopikByAppPage";
import ScrollToTop from "./components/scroll/ScrollToTop";

function App() {
  return (
    <div className="App">
      <ScrollToTop>
        <Routes>
          <Route
            path="/"
            element={
              <BasicLayout>
                <HomePage />
              </BasicLayout>
            }
          />
          <Route
            path="/support/:aplikasiName"
            element={
              <BasicLayout>
                <TopikByApp />
              </BasicLayout>
            }
          />
          <Route
            path="/search/:textSearch"
            element={
              <BasicLayout>
                <SearchingPage />
              </BasicLayout>
            }
          />
          <Route
            path="/detail/:aplikasiName/:id"
            element={
              <BasicLayout>
                <DetailTopikPage />
              </BasicLayout>
            }
          />
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;
