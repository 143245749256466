import React from "react";

export default function BasicContainer({ children }) {
  return (
    // <div className="container mx-auto mt-6 px-4 sm:px-2 max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-2xl">
    <div className="container mx-auto mt-6 px-4 sm:px-2 max-w-screen-sm lg:max-w-screen-md  lg:w-10/12 xl:max-w-screen-xl 2xl:max-w-screen-2xl">
      {children}
    </div>
  );
}
