import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { VscChromeClose, VscSearch } from "react-icons/vsc";
import { DUMMY_FAQ } from "../../dummy/FAQData";
import { useParams, useNavigate } from "react-router-dom";
import { useOnClickOutside } from "../../functioGeneral/customHookReact";

export default function SearchingInputMain() {
  const inpRef = useRef(null);
  const listRef = useRef(null);

  const [searchText, setSearchText] = useState("");
  const { t, i18n } = useTranslation();
  const [showList, setShowList] = useState(false);

  useOnClickOutside(listRef, () => setShowList(false));

  const [data, setData] = useState([]);
  let { textSearch } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    if (textSearch !== undefined) {
      setSearchText(textSearch);
    }
  }, [textSearch]);
  useEffect(() => {
    let temp = DUMMY_FAQ.filter(
      (x) =>
        x.languageDefault === i18n.language.toUpperCase() &&
        x.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setData(temp);
    if (searchText.length > 0 && temp.length > 0) {
      setShowList(true);
    }
  }, [i18n.language, searchText]);

  const handleSearch = () => {
    if (searchText.length > 0) {
      setShowList(false);
      navigate("/search/" + searchText, {
        replace: true,
      });
    }
  };
  const handleChooseItem = (id, catApp) => {
    setShowList(false);
    setSearchText("");
    navigate("/detail/" + catApp + "/" + id, {
      replace: true,
    });
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div className="relative w-full mx-2 sm:w-auto">
        <div
          className="bg-white px-8 py-2 cursor-text relative
          rounded-md border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 
          dark:bg-neutral-800
          
          
          "
          //   text-sm font-medium text-gray-700
          onClick={() => {
            inpRef.current.focus();
            if (searchText.length > 0 && data.length > 0) {
              setShowList(true);
            }
          }}
        >
          <input
            ref={inpRef}
            value={searchText}
            className="w-full sm:w-96 pr-8 bg-transparent border-transparent 
            focus:outline-none focus:border-transparent focus:ring-1 focus:ring-transparent placeholder-gray-700
            dark:placeholder-gray-400"
            type={"text"}
            placeholder={t("basic:placeHolderSearchFAQMain")}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <div className="absolute right-0 top-0 h-full flex mr-2">
            {searchText.length > 0 && (
              <button
                className=""
                onClick={(e) => {
                  setSearchText("");
                }}
              >
                <VscChromeClose className=" mr-2 h-5 w-5" />
              </button>
            )}
            <button
              className=""
              onClick={(e) => {
                if (searchText.length > 0) {
                  handleSearch();
                }
              }}
            >
              {" "}
              <VscSearch className=" mr-2 h-5 w-5" />
            </button>
          </div>
        </div>
        {showList && (
          <div
            ref={listRef}
            className="absolute bg-red-100 mt-1 border border-gray-200  w-full shadow-sm rounded-md "
          >
            <ul className="bg-white rounded-lg  w-full  dark:bg-neutral-800">
              {data.map((n, i) => (
                <li
                  key={i}
                  className="px-6 py-2 border-b border-gray-200 w-full hover:bg-gray-50 dark:hover:bg-neutral-700  cursor-pointer"
                  onClick={(e) => {
                    handleChooseItem(n.id, n.categoryApp);
                  }}
                >
                  {n.title}
                </li>
              ))}
              {/* <li className="px-6 py-2 border-b border-gray-200 w-full hover:bg-gray-50 dark:hover:bg-neutral-700 cursor-pointer">
                A second item
              </li>
              <li className="px-6 py-2 border-b border-gray-200 w-full hover:bg-gray-50 dark:hover:bg-neutral-700  cursor-pointer">
                A third item
              </li>
              <li className="px-6 py-2 border-b border-gray-200 w-full hover:bg-gray-50 dark:hover:bg-neutral-700  cursor-pointer">
                A fourth item
              </li>
              <li className="px-6 py-2 w-full hover:bg-gray-50 dark:hover:bg-neutral-700  cursor-pointer">
                And a fifth one
              </li> */}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
