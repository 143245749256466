import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleBreadcrumbs from "../../../../components/breadcrumbs/SimpleBreadcrumbs";
import BasicContainer from "../../../../components/container/BasicContainer";
import SimpleHeader from "../../../../components/header/SimpleHeader";
import ListApp from "../molekuls/ListApp";
import ListItemJoin from "../molekuls/ListItemJoin";
import { useParams, useNavigate } from "react-router-dom";
import { DUMMY_FAQ } from "../../../../dummy/FAQData";
import FeedBackUser from "../molekuls/FeedBackUser";

export default function DetailTopikPage() {
  const [data, setData] = useState({});
  const [topikJoin, setTopikJoin] = useState([]);
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    let temp = DUMMY_FAQ.find((x) => x.id === parseInt(id));
    if (
      i18n.language.toUpperCase() !== temp.languageDefault &&
      temp.language !== undefined
    ) {
      let temp2 = temp?.language.find(
        (x) => x.code === i18n.language.toUpperCase()
      ).topikId;

      navigate("/detail/" + temp.categoryApp + "/" + temp2, {
        replace: true,
      });
    }

    setData(temp);
    setTopikJoin(DUMMY_FAQ.filter((x) => temp.idTopicJoin.includes(x.id)));
  }, [id, i18n.language, navigate]);

  return (
    <>
      <SimpleHeader />
      <BasicContainer>
        <div>
          <SimpleBreadcrumbs
            list={[
              { name: "Support", link: "/" },
              { name: data.categoryApp, link: "/support/" + data.categoryApp },
              { name: data.title, link: "#" },
            ]}
          />
        </div>
        <div>
          <h2 className="cursor-pointer text-2xl font-bold">{data.title}</h2>

          <p className="text-base font-semibold text-gray-500 dark:text-gray-400">
            {data.categoryApp}
          </p>
          <p className="text-base pt-4 text-justify">{data.description}</p>
          <div
            className="text-base font-semibold "
            dangerouslySetInnerHTML={{ __html: data.htmlContent }}
          />
          {/* <div className="my-10">
            <FeedBackUser />
          </div> */}
          <div className="my-10">
            <h3 className="text-lg font-semibold capitalize mb-4">
              {t("basic:relatedTopics")}
            </h3>
            <ListItemJoin data={topikJoin} />
          </div>
          {/* <div className="my-10">
            <h3 className="text-lg font-semibold capitalize mb-4">
              {t("basic:topicAppBase")}
            </h3>
            <ListApp />
          </div> */}
        </div>
      </BasicContainer>
    </>
  );
}
