import React, { Fragment } from "react";
import { classNames } from "../../functioGeneral/propsComponent";
import { Menu, Transition } from "@headlessui/react";
import { LIST_LANGUAGE } from "../../constants/languageConstant";
import { useTranslation } from "react-i18next";
import { VscGlobe } from "react-icons/vsc";

export default function DropDownLngs() {
  const { i18n } = useTranslation();
  return (
    <Menu as="div" className="relative inline-block text-left w-40">
      <div>
        <Menu.Button
          className="inline-flex w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-200 
        
        dark:border-gray-400 dark:text-gray-400 dark:bg-neutral-900 dark:focus:ring-offset-transparent
        "
        >
          <VscGlobe className=" mr-2 h-5 w-5" />
          {LIST_LANGUAGE.find((x) => i18n.resolvedLanguage === x.code).name}
          {/* <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" /> */}{" "}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className=" origin-bottom-right absolute right-0 bottom-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none
        
         dark:bg-neutral-800 
         
         "
        >
          <div className="py-1">
            {LIST_LANGUAGE.map(({ code, name, country_code }, key) => (
              <Menu.Item key={key}>
                {({ active }) => (
                  <button
                    key={code}
                    style={{
                      fontWeight:
                        i18n.resolvedLanguage === code ? "bold" : "normal",
                    }}
                    className={classNames(
                      active
                        ? "bg-gray-100 dark:bg-neutral-900 text-gray-900 dark:text-gray-600 dark:hover:text-gray-400"
                        : "text-gray-700 dark:text-gray-400",
                      "block px-4 py-2 text-sm w-full text-left"
                    )}
                    type="submit"
                    onClick={() => i18n.changeLanguage(code)}
                  >
                    {name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
