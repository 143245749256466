import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicContainer from "../../../../components/container/BasicContainer";
import SimpleHeader from "../../../../components/header/SimpleHeader";
import { DUMMY_FAQ } from "../../../../dummy/FAQData";
import ListApp from "../molekuls/ListApp";
import ListItemTitle from "../molekuls/ListItemTitle";

export default function HomePage() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(DUMMY_FAQ);

  useEffect(() => {
    setData(
      DUMMY_FAQ.filter((x) => x.languageDefault === i18n.language.toUpperCase())
    );
  }, [i18n.language]);
  return (
    <>
      <SimpleHeader>
        <h1 className="text-3xl font-bold text-center">
          {t("basic:homePage.ask_Help")}
        </h1>
      </SimpleHeader>
      <BasicContainer>
        {/* <h3 className="text-base font-semibold"> base</h3>
        <h3 className="text-lg"> h3</h3>
        <h3 className="text-2xl font-semibold"> h2</h3>
        <h3 className="text-3xl font-semibold"> h1</h3> */}
        <h3 className="text-lg font-semibold capitalize mb-4 px-2">
          {t("basic:topFaq")}{" "}
        </h3>
        <ListItemTitle data={data} />
        <div className="my-10">
          {/* <h3 className="text-lg font-semibold capitalize mb-4">
            {t("basic:topicAppBase")}
          </h3>
          <ListApp /> */}
        </div>
      </BasicContainer>
    </>
  );
}
